import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import '../../css/supreme-logo-generator.css'
import AdBanner from "../../components/ad-banner";
import { SketchPicker, BlockPicker } from 'react-color';

// AgjtCKy

class View extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			text: "",
			errorMessage: "",
			fontSize: 60,
			backgroundColor: '#e53935',
			fontColor: '#ffffff',
			showAdvancedOptions: false,
			toggleBackgroundColorPicker: false,
			togglefontColorPicker: false,
			width: 650
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
		this.updateCanvas();
	}

	updateCanvas() {

		// Set your defaults
		var maxWidth = 0;
		var maxCharacters = 0;

		// Get a reference to 
		var canvas = document.getElementById("canvas");
		var ctx = canvas.getContext("2d");

		var canvasWidth = 0;
		var canvasHeight = 0;

		var tArray = this.state.text.split('\n');

		// Get the max num of characters!
		tArray.forEach((value, index) => {
			if (value.length > maxCharacters) {
				maxCharacters = value.length;
			}
		});

		// Initial width and height predictions
		canvasWidth = maxCharacters * 100;
		canvasHeight = (this.state.fontSize + (this.state.fontSize / 2)) * tArray.length;

		// Set the canvas width
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		tArray.forEach((value, index) => {

			if (value.trim() == '') {
				return;
			}

			ctx.textAlign = 'center';
			ctx.fillStyle = this.state.fontColor;
			ctx.font = `bold ${this.state.fontSize}px Nunito Sans`;
			ctx.fillText(
				value.trim(),
				(canvasWidth / 2),
				(this.state.fontSize * 1.05) + (index * (this.state.fontSize * 1.45))
			);

			// ctx.measureText(value).emHeightAscent
			// ctx.measureText(value).emHeightDescent

			ctx.fillStyle = this.state.backgroundColor;
			ctx.fillRect(
				(canvasWidth / 2) - ((ctx.measureText(value).width / 2 + (this.state.fontSize / 8))),
				(this.state.fontSize * 1.1) + (index * (this.state.fontSize * 1.45)) - this.state.fontSize,
				ctx.measureText(value).width + (this.state.fontSize / 4),
				this.state.fontSize + ((this.state.fontSize / 4))
			);

			console.log(ctx.measureText(value))

			ctx.textAlign = 'center';
			ctx.fillStyle = this.state.fontColor;
			ctx.font = `${this.state.fontSize}px Nunito Sans`;
			ctx.fillText(
				value.trim(),
				(canvasWidth / 2),
				(this.state.fontSize * 1.05) + (index * (this.state.fontSize * 1.45))
			);

			if (ctx.measureText(value).width > maxWidth) {
				maxWidth = ctx.measureText(value).width;
			}

		});

		canvasWidth = maxWidth + 50;
		canvasHeight = (this.state.fontSize + (this.state.fontSize / 2)) * tArray.length;

		// Set the canvas width
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		tArray.forEach((value, index) => {

			if (value.trim() == '') {
				return;
			}

			ctx.textAlign = 'center';
			ctx.fillStyle = this.state.fontColor;
			ctx.font = `bold ${this.state.fontSize}px Nunito Sans`;
			ctx.fillText(
				value.trim(),
				(canvasWidth / 2),
				(this.state.fontSize * 1.05) + (index * (this.state.fontSize * 1.45))
			);

			ctx.fillStyle = this.state.backgroundColor;
			ctx.fillRect(
				(canvasWidth / 2) - ((ctx.measureText(value).width / 2 + (this.state.fontSize / 8))),
				(this.state.fontSize * 1.1) + (index * (this.state.fontSize * 1.45)) - this.state.fontSize,
				ctx.measureText(value).width + (this.state.fontSize / 4),
				this.state.fontSize + ((this.state.fontSize / 4))
			);

			ctx.textAlign = 'center';
			ctx.fillStyle = this.state.fontColor;
			ctx.font = `${this.state.fontSize}px Nunito Sans`;
			ctx.fillText(
				value.trim(),
				(canvasWidth / 2),
				(this.state.fontSize * 1.05) + (index * (this.state.fontSize * 1.45))
			);

			if (ctx.measureText(value).width > maxWidth) {
				maxWidth = ctx.measureText(value).width;
			}

		});

		console.log('max-width: ', maxWidth);

		// -----------
		// Width: 
		// -----------

	}

	handleChange(event) {
		if (event.target.id === 'text') {

			this.setState({ text: event.target.value }, () => {
				this.updateCanvas()
			});

		} else if (event.target.id === 'font-size') {
			this.setState({ fontSize: parseInt(event.target.value) });
			this.setState({ fontSize: parseInt(event.target.value) }, () => {
				this.updateCanvas()
			});
		}
	}

	handleClick(event) {

		if (event.target.id === 'toggle-advanced-options') {

			this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions });

		} else if (event.target.id === 'toggle-background-color-picker') {
			this.setState({ toggleBackgroundColorPicker: !this.state.toggleBackgroundColorPicker });
		} else if (event.target.id === 'toggle-font-color-picker') {
			this.setState({ togglefontColorPicker: !this.state.togglefontColorPicker });
		}

	}

	getDangerousHTML = str => {
		return { __html: str };
	};

	downloadImg = function (event) {
		var el = document.getElementById('download-image');
		var image = document.getElementById('canvas').toDataURL("image/png");
		el.href = image;
	};

	render() {
		return (
			<Layout>
				<SEO
					title="Supreme Logo Generator | A Helpful Tool for creating Supreme Logo Illustrations"
					keywords={[
						'supreme logo generator',
						'supreme font generator',
						'multi-line supreme font generator',
						'supreme font copy and paste',
						'supreme logo font generator',
						'supreme font image download'
					]}
					description={"The 'Supreme Logo Generator' or the 'Supreme Font Logo Generator' is a helpful tool that is used to quickly create and download a multi-line JPEG Image in the iconic 'Supreme' style."}
				/>
				{/* <Navigation /> */}
				<div style={{ marginTop: 30 }} />
				{/* <AdBanner></AdBanner> */}
				<div style={{ marginTop: 30 }} />
				<Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col>
							<h3
								style={{
									textAlign: "center",
									fontWeight: "300"
								}}
							>
								<Link to="/products">{"⬅ Supreme Logo Generator"}</Link>
							</h3>
						</Col>
					</Row>
					<div style={{ marginTop: 10 }} />
					<Row style={{ display: "flex", justifyContent: "center" }}>
						<Col>
							<h4
								style={{
									textAlign: "center",
									fontWeight: "300"
								}}
							>
								{/* Write your Captions exactly how you want them to be displayed. */}
							</h4>
						</Col>
					</Row>
					<div style={{ marginTop: 10 }} />
				</Container>
				<Container style={{ maxWidth: this.state.width }}>
					<div className="card-2" style={{
						padding: 20
					}}>
						<Row>
							<Col>
								<Form.Group>
									<Form.Control
										id="text"
										as="textarea"
										rows="3"
										value={this.state.text}
										onChange={this.handleChange}
										placeholder={"Text goes here."}
									/>
								</Form.Group>
							</Col>
						</Row>
						{(this.state.text.length > 0)
							?
							<Row>
								<Col>
									<a id="download-image" download="supreme_logo.png" href="" onClick={this.downloadImg}><Button variant="danger" block>⇓ Download as Image</Button></a>
								</Col>
								{(this.state.showAdvancedOptions)
									?
									<Col>
										<Button id="toggle-advanced-options" variant="dark" onClick={this.handleClick} block>Hide Advanced Options</Button>
									</Col>
									:
									<Col>
										<Button id="toggle-advanced-options" variant="dark" onClick={this.handleClick} block>Show Advanced Options</Button>
									</Col>
								}
							</Row>
							:
							<Row>
								<Col>
									<Button variant="danger" block>⇓ Download as Image</Button>
								</Col>
								{(this.state.showAdvancedOptions)
									?
									<Col>
										<Button id="toggle-advanced-options" variant="dark" onClick={this.handleClick} block>Hide Advanced Options</Button>
									</Col>
									:
									<Col>
										<Button id="toggle-advanced-options" variant="dark" onClick={this.handleClick} block>Show Advanced Options</Button>
									</Col>
								}
							</Row>
						}
						<div style={{ marginTop: 30 }}></div>
						<Row>
							<Col style={{ display: 'flex', justifyContent: 'center' }}>
								<canvas
									id="canvas"
									width={this.state.width}
									height="0"
									style={{
										// border: '1px solid #d3d3d3'
									}}
								/>
							</Col>
						</Row>
						<div style={{ marginTop: 30 }}></div>
						{(this.state.showAdvancedOptions) ?
							<div style={{
								padding: 20,
								border: '1px solid #e1e1e1'
							}}>
								<Row>
									<Col>
										<Form.Group>
											<Form.Label>Select Font Size</Form.Label>
											<Form.Control as="select" id="font-size" onChange={this.handleChange}>
												<option>40</option>
												<option>50</option>
												<option selected>60</option>
												<option>70</option>
												<option>80</option>
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col style={{
										textAlign: 'center',
										border: '1px solid #e1e1e1',
										padding: 20,
										margin: 10
									}}>
										<Form.Group style={{
											display: 'flex',
											justifyContent: 'center',
											flexDirection: 'column',
										}}>
											<Form.Label>Background Color</Form.Label>
											{/* <BlockPicker
												color={this.state.backgroundColor}
												onChangeComplete={(color) => {
													this.setState({ backgroundColor: color.hex }, () => {
														this.updateCanvas();
													});
												}}
											/> */}
											<Button id="toggle-background-color-picker"
												onClick={this.handleClick}
												style={{ backgroundColor: this.state.backgroundColor, borderColor: '#e1e1e1' }} block></Button>
											{(this.state.toggleBackgroundColorPicker)
												?
												<SketchPicker
													color={this.state.backgroundColor}
													onChangeComplete={(color) => {
														this.setState({ backgroundColor: color.hex }, () => {
															this.updateCanvas();
														});
													}}
												/>
												:
												null
											}
										</Form.Group>
									</Col>
									<Col style={{
										textAlign: 'center',
										border: '1px solid #e1e1e1',
										padding: 20,
										margin: 10
									}}>
										<Form.Group style={{
											display: 'flex',
											justifyContent: 'center',
											flexDirection: 'column',
										}}>
											<Form.Label>Font Color</Form.Label>
											{/* <BlockPicker
												color={this.state.fontColor}
												onChangeComplete={(color) => {
													this.setState({ fontColor: color.hex }, () => {
														this.updateCanvas();
													});
												}}
											/> */}
											<Button id="toggle-font-color-picker"
												onClick={this.handleClick}
												style={{ backgroundColor: this.state.fontColor, borderColor: '#e1e1e1' }} block></Button>
											{(this.state.togglefontColorPicker)
												?
												<SketchPicker
													style={{
														position: 'absolute'
													}}
													color={this.state.fontColor}
													onChangeComplete={(color) => {
														this.setState({ fontColor: color.hex }, () => {
															this.updateCanvas();
														});
													}}
												/>
												:
												null
											}
										</Form.Group>
									</Col>
								</Row>
							</div>
							:
							<div></div>
						}
					</div>
					<div style={{ marginTop: 100 }}></div>
				</Container>
				<div style={{ marginTop: 100 }} />
				<Container style={{ maxWidth: this.state.width }}>
					<Row>
						<Col>
							<p>
								The 'Supreme Logo Generator' or the 'Supreme Font Logo Generator' is a helpful tool that is used to quickly create and download a JPEG Image in the pattern of the iconic 'Supreme' font.
							</p>
						</Col>
					</Row>
					{/* <AdBanner /> */}
					<Row>
						<Col>
							<p>
								All you have to do is write your text inside the textbox, preview the image and download it. It's that simple!
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								The best part? This is the only tool that supports multiple lines! So, write away and create your Supreme Font style logo today!
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								Note: This tool is in no way shape or form associated with the 'Supreme' brand or the 'Futura' font. The font being used is Nunito and not Futura.
							</p>
						</Col>
					</Row>
				</Container>
				<div style={{ marginTop: 100 }} />
				<DonationFooter ad={false} />
			</Layout>
		);
	}
}

export default View;
